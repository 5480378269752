/* @page { size: 21cm 29.7cm; margin: 2cm } */
@page {
  size: "A4";
}

@media print {
  body * {
    visibility: visible;
  }
  .div1, .div1 * {
    visibility: visible;
  }

  .div2{
    width:unset !important;
    margin: 0px !important;
  }

  .div1{
    border: none;
    padding-top: 0px !important;
  }
}

body{
  font-weight:normal !important;
}
p{
  margin-bottom: 0px !important;
  color: #000;
}


.div1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    position: relative;
}

.div2 {
  width: 900px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 20px 55px 20px 55px;
    transition: all 0.3s;
}

  
.div1 h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.div3{
  height: 110px;
  width: 300px;
  border:2px solid red;
  /* background-color: lime !important;  */
  text-align: center;
  padding-top: 10px;
  margin-left: auto;
}
.div3 p {
    color: red;
}

.div3 p{
    padding-bottom: 10px !important;
    font-weight: bold !important;
}

.margin_top{
  margin-top: 10px !important;
}

.list_table {
  width: 100%;
    height: inherit
}

.list_table th,.list_table td {
  border: 2px solid darkblue;
  font-size: 11px;
  color: #000;
  font-weight: 500;
  padding:4px;
}

.remove_border {
  /* border-bottom: none !important; */
  border-bottom: solid 2px #ffffff;
  height: 1px;
}

.add_border {
  border-top: 1px solid black;
}


.barcode_div{
  display: grid;
  min-width: 376px;
  /* background-color: skyblue ; */
  justify-content: center;
  text-align: center;

}

.calculation{
  border: 2px solid black ;
  height: 5rem;
  width: 12rem;
  margin-left: auto;
    margin-top: 40px;
}

.calculation_table td{
  border:none;
}

.calculation_table{
  border: "none";
  width: 100%;
  
}

.calculation_table td{
  padding-left: 10px;
  padding-right: 10px;
  font-size:15px;
  text-align: end;
}
