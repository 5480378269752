/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
.icon-general-style:hover {
  cursor: pointer !important;
}

.final-price {
  border: 2px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
}

.sample-invoices {
  border: 1px solid black;
}

.print-invoice{
  border: 1px solid black;
}

.hide-input-border:focus-visible {
  outline: none !important;
}

.inputcontainer {
  position: relative;
}

.icon-container {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
}
.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader::after,
.loader::before {
  content: '';
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.react-date-picker__wrapper {
  border: none !important;
}

.commonParent {
  display: flex;
  margin-top : 8px;
}
.commonTitle {
  width: 30%;
  font-weight: bold;
  font-size: 16px;
}
.commonValue {
  width: 60%;
  font-weight: bold;
  font-size: 14px;
}

.commonPrinterFontStyles {
  font-size: 15px!important;
  /* font-family: 'Roboto', sans-serif!important; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold!important;
  color: black!important;
  opacity: 1!important;
}

.commonPrintTitleStyle {
  /* font-size: 15px!important;
  font-family: 'Roboto', sans-serif!important;
  font-weight: 800!important;
  color: black!important; */
  text-align: center!important;
}

.commonPrintLeftAlignTitleStyle {
  /* font-size: 15px!important;
  font-family: 'Roboto', sans-serif!important;
  font-weight: 800!important;
  color: black!important; */
  text-align: left!important;
}

.commonPrinterSecondTitleStyle {
  width: 60%!important;
  text-align: right!important;
  /* font-size: 15px!important;
  font-family: 'Roboto', sans-serif!important;
  font-weight: 800!important;
  color: black!important; */
}

.commonPrinterValueStyle {
  margin-left: auto!important;
  /* font-size: 15px!important;
  font-family: 'Roboto', sans-serif!important;
  font-weight: 800!important;
  color: black!important; */
}

.description-input {
  outline: none; /* Removes the default blue outline */
  box-shadow: none; /* Removes the shadow in some browsers */
  border-color: transparent; /* Hides the border color */
}

@media print {
  .sample-invoices {
    width: 370px !important;
    margin-top: 100px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .print-invoice {
    width: 370px !important;
    margin-top: 10px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
