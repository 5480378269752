.title {
    font-size: 24px;
    font-weight: 500;
    color: #1B1B21;
}

.content {
    font-size: 16px;
    color: #ADAAAF;
}

.subTitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    color: #1B1B21;
}

.quantityFillBtn {
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #2F1F9C;
    border: 1px solid #2F1F9C;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.quantityOutlinedBtn {
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #F0F0F0;
    color: #1B1B21;
    font-size: 18px;
    cursor: pointer;
}

.customQuantity {
    width: auto;
    max-height: 40px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #F0F0F0 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #1B1B21 !important;
    text-align: center;
}

.customQuantityWithBorder {
    width: auto;
    max-height: 40px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #2F1F9C !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #1B1B21 !important;
    text-align: center;
}

.note {
    color: #292929 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
}

.saveBtn {
    height: 40px;
    width: 120px;
}