.colText {
    text-align: center;
    color: #5A6169;
    font-size: 14px;
}

.tableNameContainer {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    background-color: #2e209b;
    border-radius: 8px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 1.5em !important;
    padding: 1px;
}

.tableName {
    font-size: 18px;
    color: white;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    line-height: 1.5em !important;
    max-height: 6em !important;
    white-space: normal !important;
}

.orderNo {
    color: #5A6169;
    font-size: 18px;
    width: 80% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    overflow-wrap: anywhere !important;
    white-space: normal !important;
    max-height: 6em !important;
}

.orderDate {
    color: #5A6169;
    font-size: 14px;
}

.rowText {
    color: black;
    font-size: 14px;
    text-align: center;
}

.total {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.btnContainer {
    width: 100%;
    position: relative !important;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
