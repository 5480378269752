.orderSummaryCommonTitle {
    color: #292929;
    font-size: 16px;
    font-weight: 400;
}

.restaurantFoodName {
    color: #292929;
    font-size: 16px;
    font-weight: 400;
    margin-right: 4px;
    width: 75% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    overflow-wrap: anywhere !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 4 !important;
    line-height: 1.5em !important;
    max-height: 6em !important;
    white-space: normal !important;
}