.page-title  {
    color: #292929;
    font-size: 22px;
    font-style: italic;
    font-weight: bold;
}

.tableCard {
    height: 100%;
}

.tableParentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    align-self: center;
}

.tableContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   margin: 0px 25px;
   margin-bottom: 50px;
}

.tableContentContainer {
    width: 180px;
    /* min-height: 60px; */
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:5px;
    margin: 7px 0px;
    border-radius: 10px;
    background: red;
}

.tableItemContainer {
    display: flex;
}

.tableItem {
    border-radius: 10px;
    border-width:  1px;
    margin-left: 5px;
    width: 70px;
    height: 15px;
    background: #000;
}

.tableInfoFontStyle {
    /* width: 170px; */
    /* background-color: red; */
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.kotCountFontStyle {
    font-size: 16px;
    font-weight: 400;
    margin: 0px 5px;
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical; */
}

.parent {
    width: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.dropDownIcon {
    height: 15px;
    width: 15px;
    border-radius: 100px;
}

.firstCol {
    /* background-color: red; */
    text-align: start;
}

.secondCol {
    /* background-color: aqua; */
    text-align: center;
}

.thirdCol {
    /* background-color: black; */
    text-align: end;
}

.editIconContainer {
    height: 21px;
    width: 21px;
    border-radius: 3px;
    margin-left: 5px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C2C2C2;
    cursor: pointer;
}

.orderSummaryCommonTitle {
    color: #292929;
    font-size: 16px;
    font-weight: 400;
}

.itemCode {
    color: #292929;
    font-size: 12px;
    font-weight: 300;
}

.note {
    color: #292929;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
}
.noteTitle {
    color: #292929;
    font-size: 14px;
    font-weight: 500;
}

.genericNote {
    margin-top: 5px;
    border-radius: 10px;
    color: #888888 !important;
    font-size: 16px !important;
}

.hrTag {
    border-style: dotted;
    border: 1px dotted #D9D9D9;
    margin: 10px 0px;
}

.foodItemsRowParentContainer {
    width: auto;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: flex-start;
}

.foodItemsRowContainer {
    width: auto;
    display: flex;
    align-self: center;
    justify-content: space-between;
    align-items: flex-start;
}

.foodItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.foodPriceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.foodAddBtn {
    width: 80px;
    height: 35px;
    border-color: #888888;
}

.foodEditBtn {
    width: 'auto';
    height: 40px
}

.foodName {
    font-size: 18px;
    color: #292929;
    font-weight: normal;
}

.foodPrice {
    font-size: 16px;
    color: #292929;
    font-weight: normal;
    margin-top: 5px;
}

.foodCode {
    font-size: 16px;
    color: #292929;
    font-weight: 300;
}

.addToCart {
    font-size: 16px;
    color: #292929;
    font-weight: 500;
    margin-right: 3px;
}

.quantity {
    font-size: 16px;
    color: #312379;
    font-weight: 500;
}

.foodAddBtn {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    background-color: #2F1F9C;
    border: 1px solid #2F1F9C;
    color: white;
    font-size: 18px;
    margin-left: auto !important;
    margin-right: 10px !important;
    /* cursor: pointer; */
}

.foodEditdBtn {
    width: 120px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #C2C2C2;
    border: 1px solid #C2C2C2;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.foodSelectionbtnContainer {
    width: 100%;
    position: absolute !important;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    left: 0px;
    right: 0px;
    background: white;
}

.backBtn {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    background-color: #2F1F9C;
    border: 1px solid #2F1F9C;
    color: white;
    font-size: 18px;
    margin-left: 10px !important;
}

.restaurant-canvas-body {
    overflow-y: unset;
}