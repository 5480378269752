:root {
  --primary-dark: #181818;
  --secondary-dark: #303030;
  --light-dark: #262626;
  --primary-white: #e9e3e9;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,


.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 5rem;
  margin-top: 0px !important;
  padding-top: 100px !important;
  position: relative;
  width: 100%;
  height: 100%;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/*  setting menu css */

.text-hover:hover {
  background-color: #e7e8ec !important;
}

.listStyle {
  list-style: none;
  background-color: #ffffff;
  padding: 5px 20px;
}

.cardBoxShadow {
  /* box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1) !important; */
  box-shadow: none !important;
}

.activeSetting {
  background-color: #c3c7cc !important;
}

.nav-wrapper ul .nav-item:last-child {
  position: absolute;
  bottom: 0px;
  border-top: 1px solid rgba(255, 255, 255, .5) !important;
  height: 3.78rem;
}


.btn-primary,
.btn-accent {
  background-color: #2E1F9D !important;
  border-color: #7a7a7a54 !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}




/* over dark theme */

@media screen and (max-width: 480px) {
  .auth-inner {
    width: 400px;
    padding: 40px !important;
    margin-top: -53px !important;

  }



  /* .svg_container__2T9PB {
    height: 87vh !important;
  } */

  #main_page_logo {
    height: 70px !important;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: auto !important;
  }
}


/* @media screen and (max-width: 760px) { */
  /* .table-responsive {
    display: block !important;
  } */

  /* #go_back {
    background-color: red !important;
  } */


  /* .container {
    max-width: 100% !important;
  } */
/* } */

@media screen and (max-width: 420px) {
  .auth-inner {
    width: 340px;
    padding: 24px !important;

  }

  #main_page_logo {
    height: 70px !important;
  }
}

.Style_password__show__xZ2XU > .material-icons{
  opacity: 0.5;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__input{
  width: 100%;
  height: auto !important;
  padding: .4375rem .75rem !important;
  font-size: .8465rem !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  border: 1px solid #e1e5eb !important;
  font-weight: 400 !important;
  will-change: border-color,box-shadow !important;
  border-radius: .25rem !important;
  box-shadow: none !important;
  transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06) !important;
}

.react-autosuggest__input:focus{
  outline: none !important;
  border-color: #007bff !important;
  box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06) !important; 
}

:root {
  --primary-theme-color :#2C1E99; 
  --primary-text-color :#ece9e9; 
  --primary-text-hover-color :#323232; 
}



.react-autosuggest__input::-webkit-input-placeholder {
  font-weight: normal !important;
}
.react-autosuggest__input::-moz-placeholder {
  font-weight: normal !important;
}
.react-autosuggest__input::-ms-input-placeholder {
  font-weight: normal !important;
}

/*  theme color change */

.main-sidebar{
  background-color: var(--primary-theme-color) !important;
}

.main-sidebar .nav .nav-item .nav-link {
  color: var(--primary-text-color) !important;
}

.main-sidebar .nav .nav-item .nav-link.active {
  color: var(--primary-text-hover-color) !important;
}
.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover{
  color: var(--primary-text-hover-color) !important;
}
.main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item .nav-link:hover i, .main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item:hover i{
  color: var(--primary-theme-color) !important;
}

/* print */



@media print {
  body * {
    visibility: visible;
  }
  #backgroundImage, .btn-cancel{
  display: none !important;
  }
  .auth-wrapper{
    visibility: hidden;
    display: none ;;
  }
  .div1, .div1 * {
    visibility: visible;
  }
  
  .div2{
    border: 1px solid black !important;
    margin: 0px !important;
    padding: 20px 55px !important;
    box-shadow: unset !important;
    width: auto !important;
  }
  body, html, .App, #root, .navbar-light{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .div1{
    padding-top: 0px !important;
    size: A4;
    justify-content: left !important;
    display: contents !important;
  }
  #main_page_logo{
    display: none !important;
  }
  .auth-inner{
    margin: unset;
    width: unset;
    height: 0px !important;
    padding: 0px;
  }
  .auth-wrapper{
    padding: 0px !important;
  }
}

/* @page{
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  } */
.div1 {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* text-align: left; */
  position: relative;
  /* background-color: red; */
  border-radius:10px;
  /* height: 80vh;
  overflow-y: auto; */
}

.div2 {
  width: 900px;
  margin: auto;
  border-radius:10px;
  background: #ffffff;
  /* background-color: lime; */
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.8);
    padding: 20px 55px 20px 55px;
    transition: all 0.3s;
    /* height: 80vh; */
}

.div1 h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.div3{
  height: 110px;
  width: 300px;
  border:2px solid red;
  /* background-color: lime !important;  */
  text-align: center;
  padding-top: 10px;
  margin-left: auto;
}
.div3 p {
    color: red;
}

.div3 p{
    padding-bottom: 10px !important;
    font-weight: bold !important;
}

.margin_top{
  margin-top: 10px !important;
}

.list_table {
  width: 100%;
    height: inherit
}

.list_table, th, td {
  border: 2px solid darkblue;
  font-size: 11px;
  color: #000;
  font-weight: 500;
  padding:4px;
}

.remove_border {
  /* border-bottom: none !important; */
  border-bottom: solid 2px #ffffff;
  height: 1px;
}

.add_border {
  border-top: 1px solid black;
}


.barcode_div{
  display: grid;
  /* min-width: 376px; */
  /* background-color: skyblue ; */
  justify-content: center;
  text-align: center;

}

.calculation{
  border: 2px solid black ;
  height: 8rem;
  width: 12rem;
  margin-left: auto;
    margin-top: 40px;
}
.border_remove{
  border: 2px solid #FFF;
}

.calculation_table td{
  border:none;
}

.calculation_table{
  border: "none";
  width: 100%;
  
}

.calculation_table td{
  padding-left: 10px;
  padding-right: 10px;
  font-size:13px;
  /* text-align: end; */
}

.ReactModal__Overlay {
  z-index: 99 !important;
  background-color: rgb(128 ,128, 128,0.7) !important;
}
.ReactModal__Content{
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2) !important;
}

body{
  font-weight:normal !important;
}
p{
  margin-bottom: 0px !important;
  color: #000;
}

/* @page {
  size: "A4";
} */
